<template>
  <v-stepper alt-labels class="elevation-0">
    <v-stepper-header>
      <v-stepper-step style="cursor:pointer;" :color="getColor('joint_planning')" :complete="checkStatus('joint_planning')" @click="changeStatus('joint_planning')" step="1">
        <small>{{ $store.getters.translate("joint_planning") }}</small>
      </v-stepper-step>
      <v-divider/>
      <v-stepper-step style="cursor:pointer;" :color="getColor('location_requested')" :complete="checkStatus('location_requested')" @click="changeStatus('location_requested')" step="2">
        <small>{{ $store.getters.translate("location_requested") }}</small>
      </v-stepper-step>
      <v-divider/>
      <v-stepper-step style="cursor:pointer;" :color="getColor('location_agreed')" :complete="checkStatus('location_agreed')" @click="changeStatus('location_agreed')" step="3">
        <small>{{ $store.getters.translate("location_agreed") }}</small>
      </v-stepper-step>
      <v-divider/>
      <v-stepper-step style="cursor:pointer;" :color="getColor('practical_planned')" :complete="checkStatus('practical_planned')" @click="changeStatus('practical_planned')" step="4">
        <small>{{ $store.getters.translate("practical_planned") }}</small>
      </v-stepper-step>
      <v-divider/>
      <v-stepper-step style="cursor:pointer;" :color="getColor('examiner_requested')" :complete="checkStatus('examiner_requested')" @click="changeStatus('examiner_requested')" step="5">
        <small>{{ $store.getters.translate("examiner_requested") }}</small>
      </v-stepper-step>
      <v-divider/>
      <v-stepper-step style="cursor:pointer;" :color="getColor('examiner_agrees')" :complete="checkStatus('examiner_agrees')" @click="changeStatus('examiner_agrees')" step="6">
        <small>{{ $store.getters.translate("examiner_agrees") }}</small>
      </v-stepper-step>
      <v-divider/>
      <v-stepper-step style="cursor:pointer;" :color="getColor('passed_on_to_customer')" :complete="checkStatus('passed_on_to_customer')" @click="changeStatus('passed_on_to_customer')" step="7">
        <small>{{ $store.getters.translate("passed_on_to_customer") }}</small>
      </v-stepper-step>
      <v-divider/>
      <v-stepper-step style="cursor:pointer;" :color="getColor('running_projects')" :complete="checkStatus('running_projects')" @click="changeStatus('running_projects')" step="8">
        <small>{{ $store.getters.translate("running_projects") }}</small>
      </v-stepper-step>
      <v-divider/>
      <v-stepper-step style="cursor:pointer;" :color="getColor('stipel')" :complete="checkStatus('stipel')" @click="changeStatus('stipel')" step="9">
        <small>{{ $store.getters.translate("stipel") }}</small>
      </v-stepper-step>
      <v-divider/>
      <v-stepper-step style="cursor:pointer;" :color="getColor('plans_completed')" :complete="checkStatus('plans_completed')" @click="changeStatus('plans_completed')" step="10">
        <small>{{ $store.getters.translate("plans_completed") }}</small>
      </v-stepper-step>
    </v-stepper-header>
  </v-stepper>
</template>

<script>
export default {
  props: ["exam"],
  methods: {
    checkStatus(status) {
      if (!this.exam.statuses) {
        return false;
      }
      return !!this.exam.statuses.find((status_node) => status_node.name === status);
    },
    changeStatus(status) {
      let payload = {
        exam_id: this.exam.id,
        status: status
      }
      if (this.exam.statuses && this.exam.statuses.find((status_node) => status_node.name === status)) {
        payload.action = 'remove';
      }
      else {
        payload.action = 'set';
      }
      this.$http
          .post(this.$store.getters.appUrl + "v2/update-exam-status", payload)
          .then((response) => {
            this.exam.statuses = response.data;
            this.$toasted.success(this.$store.getters.translate("success"));
          })
          .catch((error) => {
            this.$toasted.error(error);
          });
    },
    getColor(status) {
      return this.checkStatus(status) ? "primary" : "#9E9E9E";
    },
  },
};
</script>